<template>
    <div>
        <b-card>
            <div class="m-2">
                <validation-observer ref="updateAttribute" #default="{ invalid }">
                    <b-row class="filter-row" style="padding: 0 10px">
                        <b-col cols="12" md="6" class="px-0 pb-1">
                            <h4 style="font-size: 18px;font-weight: 600;">ویرایش ویژگی</h4>
                            <p style="font-size: 14px;font-weight: 400;">ویژگی ها را از این قسمت ویرایش کنید.</p>
                        </b-col>
                    </b-row>
                    <!-- Table Top -->

                    <b-row>
                        <b-col class="p-0 mb-1" cols="12" md="4" style="padding: 0 10px !important">
                            <h6>عنوان ویژگی</h6>
                            <validation-provider #default="{ errors }" name="label" rules="required">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-form-input v-model="formData.label" class="d-inline-block"
                                        placeholder="عنوان ویژگی را وارد کنید"
                                        :state="errors.length > 0 ? false : null" />
                                </div>
                                <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col class="p-0 mb-1" cols="12" md="4" style="padding: 0 10px !important">
                            <h6>اسلاگ</h6>
                            <validation-provider #default="{ errors }" name="slug" rules="required">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-form-input v-model="formData.slug" class="d-inline-block"
                                        placeholder="اسلاگ را انگلیسی وارد کنید"
                                        :state="errors.length > 0 ? false : null" />
                                </div>
                                <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col class="px-0" dir="ltr" cols="12" md="4" style="padding: 17px 10px 0 10px !important">
                            <b-button class="d-flex justify-content-center align-items-center" variant="primary"
                                type="submit" :disabled="invalid || isLoading" @click="updateAttribute">
                                <span class="text-nowrap">ویرایش ویژگی</span>
                                <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
                <validation-observer ref="addAttributeValue" #default="{ invalid }">
                    <b-row>
                        <b-col class="p-0 mb-1" cols="12" md="4" style="padding: 0 10px !important">
                            <h6>میزان </h6>
                            <validation-provider #default="{ errors }" name="value" rules="required">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-form-input dir="ltr" v-model="formDataTwo.value" class="d-inline-block"
                                        style="text-align: right;" placeholder="مقدار را وارد کنید"
                                        :state="errors.length > 0 ? false : null" />
                                </div>
                                <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col class="px-0" dir="ltr" cols="12" md="8" style="padding: 17px 10px 0 10px !important">
                            <b-button class="d-flex justify-content-center align-items-center" variant="primary"
                                type="submit" :disabled="invalid || isLoading" @click="createAttributeValue">
                                <span class="text-nowrap">افزودن میزان جدید</span>
                                <b-spinner v-show="isLoadingTwo" class="position-absolute" small type="grow" />
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
            <!-- Table -->
            <LoadingComponent v-if="loading" />
            <b-col style="padding: 0 15px !important" v-else>
                <div v-if="attributeValues.length > 0">
                    <div class="pt-1 pb-1 overflow" v-if="attributeValues.length > 0">
                        <vue-good-table :sort-options="{ enabled: false }" :columns="columns" :rows="attributeValues"
                            :rtl="false" class="w-100">
                            <div slot="emptystate" class="w-100 text-center">
                                موردی یافت نشد
                            </div>
                            <template slot="table-row" slot-scope="props" class="mx-auto">
                                <!-- Column: Name -->
                                <span v-if="props.column.field === 'id'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.id }}</span>
                                </span>
                                <span v-if="props.column.field === 'value'" class="text-nowrap">
                                    <span dir="ltr" class="text-nowrap">{{ props.row.value }}</span>
                                </span>

                                <!-- Column: Action -->
                                <span v-else-if="props.column.field === 'action'" style="width: 100px !important">
                                    <span>
                                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret
                                            :right="true">
                                            <template v-slot:button-content>
                                                <feather-icon icon="MoreVerticalIcon" size="16"
                                                    class="text-body align-middle mr-25" />
                                            </template>
                                            <b-dropdown-item class="update-value" v-b-modal.modal-prevent-closing
                                                @click="showUpdateModal(props.row.id)">
                                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                                <span>ویرایش</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="deletePrompt(props.row.id)">
                                                <feather-icon icon="TrashIcon" class="mr-50" />
                                                <span>حذف</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                    <b-pagination v-model="currentPage" :total-rows="totalAttributeValues" :per-page="perPage"
                        first-number last-number align="left" prev-class="prev-item" next-class="next-item"
                        class="mt-1 mb-0 p-0" dir="ltr">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
                <div v-else>
                    <h3 class="text-center">موردی یافت نشد</h3>
                </div>
            </b-col>
        </b-card>
        <!-- add to menu items modal -->
        <div id="modal-template" v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <b-card class="modal-container">
                            <div class="modal-header px-0 pt-0">
                                <h5 class="mb-0">
                                    ویرایش مقدار ویژگی
                                </h5>
                            </div>
                            <div class="modal-body px-0 pb-0">
                                <label for="value">مقدار:</label>
                                <b-form-input id="value" v-model="value" type="text" placeholder="مقدار" />
                            </div>
                            <div class="modal-footer">
                                <b-button class="mt-1" variant="outline-primary" @click="hideUpdateModal()">
                                    <span class="text-nowrap">بستن</span>
                                </b-button>
                                <b-button class="mt-1" variant="primary" :disabled="value.length < 1"
                                    @click.prevent="updateAttributeValue()">
                                    <span class="text-nowrap">ثبت</span>
                                </b-button>
                            </div>
                        </b-card>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import {
    BAvatar,
    BCard,
    BSpinner,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BPagination,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BForm,
} from "bootstrap-vue";
import axios from "axios";
import { VueGoodTable } from "vue-good-table";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoadingComponent from "../../components/LoadingComponent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        VueGoodTable,
        BAvatar,
        BCard,
        BSpinner,
        BFormInput,
        BRow,
        BTable,
        BCol,
        BButton,
        BPagination,
        BDropdownItem,
        BDropdown,
        ToastificationContent,
        LoadingComponent,
        BFormGroup,
        BForm,
    },
    data() {
        return {
            required,
            isLoading: false,
            isLoadingTwo: false,
            loading: false,
            attributeValues: [],
            attributeValuesLength: 0,
            attributeValuesTray: [],
            totalAttributeValues: 0,
            perPage: 15,
            currentPage: 1,
            columns: [
                {
                    label: "شناسه",
                    field: "id",
                },
                {
                    label: "میزان",
                    field: "value",
                },
                {
                    label: "عملیات",
                    field: "action",
                },
            ],
            formData: {
                label: null,
                slug: null,
            },
            formDataTwo: {
                attribute_id: null,
                value: null,
            },
            value: '',
            showModal: false,
            currentValueId: null,
        };
    },
    methods: {
        showUpdateModal(id) {
            this.showModal = true
            this.currentValueId = id
        },
        hideUpdateModal() {
            this.showModal = false
            this.currentValueId = null
        },
        updateAttributeValue() {
            this.isLoading = true;
            axios.post(`/api/v1/admin/posts/attributes/value/${this.currentValueId}/update`, { attribute_id: this.attributeRouteId, value: this.value }).then((res) => {

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "مقدار ویژگی ویرایش شد",
                        icon: "CheckIcon",
                        text: "مقدار ویژگی با موفیقت ویرایش شد",
                        variant: "success",
                    },
                });

                this.isLoading = false;
                this.value = ""
                this.viewAttributeValue()
                this.hideUpdateModal()
            }).catch((err) => {

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "خطا",
                        icon: "XOctagonIcon",
                        text: "مشکلی در ویرایش مقدار ویژگی پیش آمده.",
                        variant: "danger",
                    },
                });

                this.isLoading = false;
                this.value = ""
            })
        },
        viewAttribute() {
            this.isLoading = true;
            axios
                .get(`/api/v1/admin/posts/attributes/${this.attributeRouteId}/view`)
                .then((response) => {
                    this.isLoading = false;
                    let res = response.data.data
                    this.formDataTwo.attribute_id = res.id
                    this.formData.slug = res.slug
                    this.formData.label = res.label
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در نمایش ویژگی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        viewAttributeValue() {
            this.loading = true;
            this.attributeValues = [];
            axios
                .get(`/api/v1/admin/posts/attributes/${this.attributeRouteId}/values`, { params: { page: this.currentPage } })
                .then((response) => {
                    let res = response.data.data

                    this.attributeValuesLength = response.data.data.length;
                    this.totalAttributeValues = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;

                    if (this.attributeValuesLength > 0) {
                        this.attributeValuesTray = response.data.data;

                        this.attributeValuesTray.forEach((item) => {
                            this.attributeValues.push({
                                id: item.id,
                                value: item.value,
                            });
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در نمایش میزان های ویژگی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        updateAttribute() {
            this.isLoading = true;
            axios
                .post(`/api/v1/admin/posts/attributes/${this.attributeRouteId}/update`, this.formData)
                .then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "ویژگی ویرایش شد",
                                icon: "CheckIcon",
                                text: "ویژگی با موفیقت ویرایش شد",
                                variant: "success",
                            },
                        });
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در ویرایش ویژگی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        createAttributeValue() {
            this.formDataTwo.attribute_id = this.attributeRouteId
            this.isLoadingTwo = true;
            axios
                .post("/api/v1/admin/posts/attributes/value/create", this.formDataTwo)
                .then((response) => {
                    if (response.status == 201) {
                        this.isLoadingTwo = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "ویژگی جدید ساخته شد",
                                icon: "CheckIcon",
                                text: "ویژگی جدید با موفیقت ساخته شد",
                                variant: "success",
                            },
                        });
                    }
                    this.viewAttributeValue()
                })
                .catch((error) => {
                    this.isLoadingTwo = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        deletePrompt(id) {
            this.$bvModal
                .msgBoxConfirm('آیا از حذف مطمئن هستید؟', {
                    title: '',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'بله',
                    cancelTitle: 'خیر',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value == true) {
                        this.deleteAttributeValue(id)
                    }
                })
        },
        deleteAttributeValue(id) {
            axios
                .post(`/api/v1/admin/posts/attributes/value/${id}/delete`)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "میزان ویژگی حذف شد",
                            icon: "CheckIcon",
                            text: "میزان ویژگی با موفیقت حذف شد.",
                            variant: "info",
                        },
                    });
                    this.viewAttributeValue();
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در حذف میزان ویژگی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        }
    },
    mounted() {
        this.attributeRouteId = router.currentRoute.params.id;
        this.viewAttribute()
        this.viewAttributeValue()
        this.$watch("currentPage", (newCurrentPage) => {
            this.viewAttributeValue();
        });

        document.addEventListener("click", (e) => {
            if (
                !e.target.classList.contains("modal-container") &&
                !e.target.parentNode.classList.contains("modal-container") &&
                !e.target.parentNode.parentNode.classList.contains("modal-container") &&
                !e.target.parentNode.parentNode.parentNode.classList.contains("modal-container") &&
                !e.target.parentNode.parentNode.parentNode.parentNode.classList.contains("modal-container") &&
                !e.target.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains("modal-container") &&
                !e.target.classList.contains("dropdown-item") &&
                !e.target.parentNode.classList.contains("dropdown-item") &&
                this.showModal == true
            ) {
                this.hideUpdateModal()
            }
        });
    },
    unmounted() {
        this.$watch("currentPage");
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
    width: 90px;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 16;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-header h3 {
    margin-top: 0;
    color: #3B4F93;
}

.modal-default-button {
    float: right;
    border: none;
    border-radius: 2px;
    padding: 10px;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>